import { useScrollable } from '@/hooks/Index'
import { Action1 } from '@/utils/Types'
import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import CaretIcon from '../CaretIcon'
type Option = Record<string, any>
/**
 * A dropdown component.
 *
 * @param {string} value The value of the dropdown.
 * @param {InputHTMLAttributes<HTMLInputElement>} input The input element's props.
 * @param {Action1<Option>} onSelect The function to call when an option is selected.
 * @param {Array<Option>} options The options of the dropdown.
 * @param {Boolean | Option} clearable Whether the value can be cleared. Specify the default option after clear.
 * @param {string} outerCls The outermost class of the component.
 * @param {string} innerCls The class of the dropdown container.
 * @param {string} menuCls The class of the dropdown menu.
 * @param {string} optionCls The class of the dropdown option.
 * @param {boolean} border Whether the dropdown has a border. Specify the border width.
 * @param {boolean} overlay Whether the dropdown has an overlay.
 */
export const Dropdown: FC<{
  value: string
  input: InputHTMLAttributes<HTMLInputElement>
  onSelect: Action1<Option>
  options: Array<Option>
  clearable?: Boolean | Option
  outerCls?: string
  innerCls?: string
  menuCls?: string
  optionCls?: string
  border?: boolean
  overlay?: boolean
}> = ({ value, input, options, onSelect, clearable, outerCls, innerCls, menuCls, optionCls, border, overlay }) => {
  const [isOpen, setIsOpen] = useState(false)
  useScrollable(isOpen)
  const wrapperRef = useRef(null)
  useEffect(() => {
    function onClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', onClickOutside)
    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [wrapperRef])
  return (
    <>
      {overlay && isOpen ? <div className='fixed inset-0 z-[8] h-screen w-screen bg-stone-800/50 ease-in-out'></div> : null}
      <div ref={wrapperRef} className={`relative${isOpen ? ' z-[9]' : ''} ${outerCls || ''}`}>
        <div
          className={`mt-2 flex items-center bg-white/100 px-2 py-2 shadow-lg shadow-stone-200 transition md:px-4 ${isOpen ? 'rounded-t-md' : 'rounded-md'}${
            border ? ' border-2' : isOpen ? ' border-b' : ''
          } ${innerCls || ''}`}
        >
          <input
            {...input}
            readOnly
            onClick={e => {
              e.stopPropagation()
              setIsOpen(!isOpen)
            }}
            className={`outline-none ${input.className || ''}`}
          />

          {value && (clearable?.['value'] ? clearable['value'] != value : !!clearable) ? (
            <svg
              viewBox='0 0 24 24'
              className='mr-2 h-3 w-3 cursor-pointer rounded-full bg-neutral-500 bg-opacity-50 text-black hover:bg-neutral-300'
              onClick={() => {
                onSelect(clearable?.['value'] ? clearable : null)
                setIsOpen(false)
              }}
            >
              <path
                d='M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z'
                fill='currentColor'
              />
            </svg>
          ) : null}

          <CaretIcon contentOpen={isOpen} className='h-4 w-4 cursor-pointer' onClick={() => setIsOpen(!isOpen)} />
        </div>
        <div
          className={`absolute z-10 max-h-48 w-full overflow-y-auto rounded-b-md bg-white/100 transition ${
            border ? 'top-[calc(100%-1px)] border-x-2 border-b-2' : 'top-full'
          } ${isOpen ? 'animate-[fade-in-down_150ms_ease-out]' : 'hidden animate-[fade-out-up_150ms_ease-out_forwards]'} ${menuCls || ''}`}
        >
          {options.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                onSelect(item)
                setIsOpen(false)
              }}
              disabled={!isOpen || item['value'] == value}
              className={`w-full px-2 py-2 text-left transition last:rounded-b-md enabled:bg-white/100 enabled:hover:bg-amber-v1 disabled:bg-amber-v1 md:px-4 ${
                border ? 'border-b last:border-0' : 'border-t first:border-none'
              } ${optionCls}`}
            >
              {item['text'] || item}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}
export default Dropdown
