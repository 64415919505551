import { svgs } from '@/utils/Images'
import { useRef, useState } from 'react'
import { TextInputProps } from './props'

export const TextInput = ({
  label,
  input,
  visibility,
  showValidated,
  valid,
  required,
  tipMessage,
  innerCls,
  labelCls,
  outerCls,
  prepend,
  append,
  noBorder,
}: TextInputProps) => {
  const [isFocus, setIsFocus] = useState(false)
  const textClass = showValidated ? (valid ? 'text-success' : 'text-error') : 'text-black'
  const borderClass = showValidated ? (valid ? 'border-success' : 'border-error') : isFocus ? 'border-cyan-v2' : 'border-stone-200'

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const divRef = useRef<HTMLInputElement | null>(null)

  return (
    <div className={`${textClass} ${outerCls || ''}`}>
      {label ? (
        <label className={`font-medium text-black ${labelCls || ''}`}>
          {label}
          {required && <span className='ml-1 text-error'>*</span>}
        </label>
      ) : null}
      <div
        ref={divRef}
        className={`mt-2 flex items-center rounded-md bg-white/100 px-2 py-2 shadow-lg shadow-stone-200 transition md:px-4${
          noBorder ? '' : ' border-2'
        } ${borderClass} ${innerCls || ''}`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      >
        {prepend}
        <input
          tabIndex={0}
          {...input}
          type={visibility && isVisible ? 'text' : input.type}
          className={`w-full text-sm outline-none md:text-base ${input.className || ''}`}
        />
        {append}
        {visibility && (
          <span className='h-6 w-6 cursor-pointer text-neutral-600' onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? svgs.visibility : svgs.visibility_off}
          </span>
        )}
        {showValidated && (
          <svg
            className='cursor-pointer'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='currentColor'
            onClick={() => {
              if (!valid && divRef?.current) {
                const inputElm = divRef.current.getElementsByTagName?.('input')?.[0]
                if (inputElm) {
                  inputElm.value = ''
                }
              }
            }}
          >
            <circle cx='7' cy='7' r='7' />
            {valid ? (
              <path d='M4 7.91638L6.08398 10L10 5' stroke='white' />
            ) : (
              <path
                d='M4.12588 4.12588C4.16568 4.08598 4.21297 4.05432 4.26502 4.03272C4.31708 4.01112 4.37288 4 4.42924 4C4.4856 4 4.54141 4.01112 4.59347 4.03272C4.64552 4.05432 4.6928 4.08598 4.73261 4.12588L7.00011 6.39424L9.26762 4.12588C9.30746 4.08604 9.35475 4.05444 9.4068 4.03288C9.45885 4.01132 9.51464 4.00022 9.57098 4.00022C9.62732 4.00022 9.68311 4.01132 9.73516 4.03288C9.78721 4.05444 9.83451 4.08604 9.87434 4.12588C9.91418 4.16572 9.94578 4.21301 9.96734 4.26507C9.9889 4.31712 10 4.3729 10 4.42924C10 4.48558 9.9889 4.54137 9.96734 4.59342C9.94578 4.64547 9.91418 4.69277 9.87434 4.73261L7.60598 7.00011L9.87434 9.26762C9.91418 9.30746 9.94578 9.35475 9.96734 9.4068C9.9889 9.45885 10 9.51464 10 9.57098C10 9.62732 9.9889 9.68311 9.96734 9.73516C9.94578 9.78721 9.91418 9.83451 9.87434 9.87434C9.83451 9.91418 9.78721 9.94578 9.73516 9.96734C9.68311 9.9889 9.62732 10 9.57098 10C9.51464 10 9.45885 9.9889 9.4068 9.96734C9.35475 9.94578 9.30746 9.91418 9.26762 9.87434L7.00011 7.60598L4.73261 9.87434C4.69277 9.91418 4.64547 9.94578 4.59342 9.96734C4.54137 9.9889 4.48558 10 4.42924 10C4.3729 10 4.31712 9.9889 4.26507 9.96734C4.21301 9.94578 4.16572 9.91418 4.12588 9.87434C4.08604 9.83451 4.05444 9.78721 4.03288 9.73516C4.01132 9.68311 4.00022 9.62732 4.00022 9.57098C4.00022 9.51464 4.01132 9.45885 4.03288 9.4068C4.05444 9.35475 4.08604 9.30746 4.12588 9.26762L6.39424 7.00011L4.12588 4.73261C4.08598 4.6928 4.05432 4.64552 4.03272 4.59347C4.01112 4.54141 4 4.4856 4 4.42924C4 4.37288 4.01112 4.31708 4.03272 4.26502C4.05432 4.21297 4.08598 4.16568 4.12588 4.12588Z'
                fill='white'
              />
            )}
          </svg>
        )}
      </div>
      {tipMessage && <div className='mt-1 text-xs'>{tipMessage}</div>}
    </div>
  )
}
export default TextInput
