import { FC, HTMLAttributes, PropsWithChildren, useRef } from 'react'
export const LazyDiv: FC<PropsWithChildren<{ isActive: boolean } & HTMLAttributes<HTMLDivElement>>> = ({ isActive, className, children, ...rest }) => {
  const isRendered = useRef<boolean>(isActive) // the first render
  if (isActive && !isRendered.current) {
    isRendered.current = true
  }
  if (!isRendered.current) {
    return null
  }
  // Subsequent renders will only change the display css
  return (
    <div {...rest} className={`animate-fade-in-down ${isActive ? className ?? '' : 'hidden'}`}>
      {children}
    </div>
  )
}
export default LazyDiv
