import CaretIcon from '@/components/CaretIcon'
import LazyDiv from '@/components/LazyDiv'
import { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react'

export const ExpandableBlock: FC<{
  title: ReactNode
  titleBtn?: ReactNode
  titleWrapCls?: string
  titleTxtWrapCls?: string
  titleTxtCls?: string
  descr?: ReactNode
  caretCls?: string
  togglerProps?: HTMLAttributes<HTMLDivElement>
  outerCls?: string
  innerCls?: string
  openDefault?: boolean
  lazy?: boolean
}> = ({ title, titleBtn, titleWrapCls, titleTxtWrapCls, titleTxtCls, descr, caretCls, togglerProps, outerCls, innerCls, children, openDefault, lazy }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (openDefault) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [openDefault])

  return (
    <div className={`card-container ${visible ? 'pb-4' : 'pb-0'} ${outerCls || ''}`}>
      <div {...togglerProps} className={`flex items-center ${togglerProps?.className || ''}`}>
        <div onClick={titleBtn ? null : () => setVisible(prev => !prev)} className={`flex w-full items-center ${titleWrapCls || ''}`}>
          <div onClick={titleBtn ? () => setVisible(prev => !prev) : null} className={`w-full cursor-pointer py-4 pl-2 md:pl-4 ${titleTxtWrapCls || ''}`}>
            <div className={`font-semibold ${titleTxtCls || ''}`}>{title}</div>
            {descr}
          </div>
          {titleBtn}
        </div>
        <div
          onClick={() => setVisible(prev => !prev)}
          className={`flex cursor-pointer items-center justify-center pr-2 outline-none md:pr-4 ${descr ? 'h-[76px]' : 'h-[60px]'} ${caretCls || ''}`}
        >
          <CaretIcon contentOpen={visible} />
        </div>
      </div>
      {lazy ? (
        <LazyDiv isActive={visible} className={`mx-2 md:mx-4 ${innerCls || ''}`}>
          {children}
        </LazyDiv>
      ) : visible ? (
        <div className={`mx-2 animate-fade-in-down md:mx-4 ${innerCls || ''}`}>{children}</div>
      ) : null}
    </div>
  )
}
export default ExpandableBlock
