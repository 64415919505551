import { svgs } from '@/utils/Images'
import { FC, HTMLAttributes, useMemo, useRef } from 'react'
import { NumberInputProps } from './props'

export const NumRangeInput: FC<Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & NumberInputProps> = ({
  value,
  onChange,
  input,
  max,
  className,
  hideTip,
  ...rest
}) => {
  const percentage = useMemo<number>(() => {
    const percentage = value === 1 ? 0 : value === max ? 100 : Math.floor(((value - 1) / (max - 1)) * 100)
    return percentage
  }, [max, value])
  return (
    <div {...rest} className={`flex items-baseline gap-x-2 text-xs ${className || ''}`}>
      <span className='text-xs text-neutral-600'>Min.&nbsp;1</span>
      <div className='relative -top-0.5 w-full'>
        {hideTip ? null : <Tip value={value} percentage={percentage} />}
        <input
          {...input}
          type='range'
          min={1}
          max={max}
          step={1}
          value={value}
          onChange={e => onChange(Number(e.target.value))}
          className={`relative outline-none ${hideTip ? 'mt-0' : 'mt-10'}`}
          style={{
            backgroundImage: `linear-gradient(to right, #FFD567 0%, #FFB800 ${percentage}%, #AAA9A9 ${percentage}%, #AAA9A9 100%)`,
          }}
        />
      </div>
      <span className='text-xs text-neutral-600'>Max.&nbsp;{max}</span>
    </div>
  )
}

const Tip: FC<{ value: number; percentage: number }> = ({ value, percentage }) => {
  const tip = useRef(null)
  const offset = tip.current ? (tip.current?.parentNode.clientWidth * (100 - percentage) - tip.current?.clientWidth * 100) / 100 : 0
  return (
    <div
      ref={tip}
      className='absolute top-0 flex w-max items-center py-1 px-2 text-sm'
      style={{ left: offset < 0 ? `calc(${percentage}% + ${offset}px)` : `${percentage}%` }}
    >
      <svg className='inline-block h-3 w-3 text-cyan-v2' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
        {svgs.ticket}
      </svg>
      &nbsp;
      {value}
    </div>
  )
}

export default NumRangeInput
