import { FC, HTMLAttributes } from 'react'
import { NumberInputProps } from './props'

export const NumberInput: FC<Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & NumberInputProps> = ({ value, onChange, input, max, className, ...rest }) => {
  const onClickMinus = () => {
    if (value > 1) {
      onChange(Number(value) - 1)
    }
  }
  const onClickAdd = () => {
    if (value < max) {
      onChange(Number(value) + 1)
    }
  }
  const _onChange = e => {
    if (!e.currentTarget.value) {
      onChange(1)
    } else {
      const value = e.currentTarget.value.replace(/[^\d]+/, '')
      onChange(value <= 1 ? 1 : value >= max ? max : value)
    }
  }
  const onBlur = e => {
    const value = e.currentTarget.value
    if (value === '' || value < 1) {
      onChange(1)
    }
  }
  return (
    <div {...rest} className={`flex w-fit flex-row items-center justify-center rounded-lg border border-neutral-300 ${className || ''}`}>
      <button aria-label='Decrease number' onClick={onClickMinus} disabled={value === 1 || input?.disabled} className='px-3 text-lg disabled:text-neutral-400'>
        -
      </button>
      <input
        {...input}
        type='number'
        step={1}
        min={1}
        max={max}
        value={value}
        onChange={_onChange}
        onBlur={onBlur}
        className='flex w-12 bg-transparent text-center font-medium focus:outline-none focus:ring-1 focus:ring-yellow-400'
      />
      <button aria-label='Increase number' onClick={onClickAdd} disabled={value === max || input?.disabled} className='px-3 text-lg disabled:text-neutral-400'>
        +
      </button>
    </div>
  )
}

export default NumberInput
